<template>
  <div class="app-container">
    <el-card class="box-card">
      <div slot="header" class="clearfix">Release Daily Reward</div>
      <div class="filter-container">
        <el-date-picker
          v-model="listQuery.date"
          type="datetime"
          placeholder="Date"
          size="small"
          class="filter-item"
          default-time="12:00:00"
        />
        <el-button
          type="danger"
          size="small"
          class="filter-item"
          @click="handleReleaseDailyReward"
          >Release</el-button
        >
      </div>
    </el-card>
    <el-card class="box-card">
      <div slot="header" class="clearfix">Release 7 Days Bonus</div>
      <div class="filter-container">
        <el-button
          type="danger"
          size="small"
          class="filter-item"
          @click="handleBonus7DayDistribution"
          >Release</el-button
        >
      </div>
    </el-card>
    <el-card class="box-card">
      <div slot="header" class="clearfix">Release Cycle Reward</div>
      <div class="filter-container">
        <el-form size="small">
          <el-form-item label="CycleId" style="width: 260px;">
            <el-input v-model="listQuery.CycleId"/>
          </el-form-item>
          <el-button
            type="danger"
            size="small"
            class="filter-item"
            @click="handleCycleDistributeReward"
            >Release</el-button
          >
        </el-form>
      </div>
    </el-card>
    <el-card class="box-card">
      <div slot="header" class="clearfix">Reset (Clear Trade , Wallet Balance & All Release Data)</div>
      <div class="filter-container">
        <el-button
          type="danger"
          size="small"
          class="filter-item"
          @click="handleResetReward"
          >Reset</el-button
        >
      </div>
    </el-card>
    <el-card class="box-card">
      <div slot="header" class="clearfix">Start / Stop (Crawl Bybits Record)</div>
      <div class="filter-container">
        <el-button
          type="danger"
          size="small"
          class="filter-item"
          @click="handleStartStop"
          >{{data.IsRunning ? "Stop" : "Start"}}</el-button
        >
      </div>
    </el-card>
    <el-card class="box-card">
      <div slot="header" class="clearfix">Generate ByBit SubKey</div>
      <div class="filter-container">
        <el-button
          type="danger"
          size="small"
          class="filter-item"
          @click="handleGenerateByBitSubKey"
          >Generate</el-button
        >
      </div>
    </el-card>
    <el-card class="box-card">
      <div slot="header" class="clearfix">POT Fee</div>
      <div class="filter-container">
        <el-form size="small">
          <el-form-item label="Claim Fee" style="width: 260px;">
            <el-input v-model="data.ClaimPOTFee"/>
          </el-form-item>
          <el-form-item label="Swap Fee" style="width: 260px;">
            <el-input v-model="data.SwapPOTFee"/>
          </el-form-item>
          <el-form-item label="Fee Recipient Email" style="width: 260px;">
            <el-input v-model="data.POTFeeRecipient"/>
          </el-form-item>
          <el-button
            type="danger"
            size="small"
            class="filter-item"
            @click="savePOTFee"
            >Save</el-button
          >
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  name: "Dashboard",
  data() {
    return {
      listQuery: {
        date: new Date(),
        CycleId: 0,
      },
      data: {},
    };
  },
  computed: {},
  created() {
    this.getData();
  },
  methods: {
    getData() {
      request({
        url: "/api/admin/settings/getPotConfig",
        method: "get",
      }).then(response => {
        this.data = response.data.Data;
      });
    },
    async handleReleaseDailyReward() {
      await this.$confirm('确认继续操作', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      })
      request({
        url: "/api/admin/settings/dailyDistributeReward",
        method: "get",
        params: {
          date: this.listQuery.date
        }
      }).then(response => {
        this.getData();
        this.$notify({
          title: "成功",
          message: "操作成功",
          type: "success",
          duration: 2000
        });
      });
    },
    async handleBonus7DayDistribution() {
      await this.$confirm('确认继续操作', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      })
      request({
        url: "/api/admin/settings/Bonus7DayDistribution",
        method: "get",
      }).then(response => {
        this.getData();
        this.$notify({
          title: "成功",
          message: "操作成功",
          type: "success",
          duration: 2000
        });
      });
    },
    async handleCycleDistributeReward() {
      await this.$confirm('确认继续操作', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      })
      request({
        url: "/api/admin/settings/CycleDistributeReward",
        method: "get",
        params: {
          CycleId: this.listQuery.CycleId
        }
      }).then(response => {
        this.getData();
        this.$notify({
          title: "成功",
          message: "操作成功",
          type: "success",
          duration: 2000
        });
      });
    },
    async handleResetReward() {
      await this.$confirm('确认继续操作', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      })
      request({
        url: "/api/admin/settings/ResetReward",
        method: "get",
        params: {}
      }).then(response => {
        this.getData();
        this.$notify({
          title: "成功",
          message: "操作成功",
          type: "success",
          duration: 2000
        });
      });
    },
    async handleStartStop() {
      await this.$confirm('确认继续操作', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      })
      request({
        url: "/api/admin/settings/StartStop",
        method: "get",
        params: {}
      }).then(response => {
        this.getData();
        this.$notify({
          title: "成功",
          message: "操作成功",
          type: "success",
          duration: 2000
        });
      });
    },
    async handleGenerateByBitSubKey() {
      await this.$confirm('确认继续操作', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      })
      request({
        url: "/api/admin/settings/GenerateByBitSubKey",
        method: "get",
        params: {}
      }).then(response => {
        this.getData();
        this.$notify({
          title: "成功",
          message: "操作成功",
          type: "success",
          duration: 2000
        });
      });
    },
    async savePOTFee() {
      await this.$confirm('确认继续操作', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      })
      request({
        url: "/api/admin/settings/SavePOTFee",
        method: "post",
        data: {
          ClaimPOTFee: this.data.ClaimPOTFee,
          SwapPOTFee: this.data.SwapPOTFee,
          POTFeeRecipient: this.data.POTFeeRecipient,
        }
      }).then(response => {
        this.getData();
        this.$notify({
          title: "成功",
          message: "操作成功",
          type: "success",
          duration: 2000
        });
      });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.app-container {
  width: 100%;
  height: 100%;
  padding: 30px;
  box-sizing: border-box;
  .box-card + .box-card {
    margin-top: 15px;
  }
}
</style>
